$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
// Redefining breakpoints because I don't want them to depend on Bootstrap.
$oi-expandable-breakpoint-sm: 445px;
$oi-expandable-breakpoint-md: 768px;
$oi-expandable-breakpoint-lg: 992px;
$oi-expandable-breakpoint-xl: 1200px;
$oi-expandable-color-expanded: white;
$oi-expandable-item-size-min: 334px;
$oi-expandable-item-size-mid: 446px;
$oi-expandable-item-size-max: 572px;
$oi-expandable-transition-speed: 0.4s;
$oi-expandable-box-margin: 8px;

@media (max-width: $oi-expandable-breakpoint-sm) {
  section.p-50 {
    padding: 50px 0 !important;
  }
}

.oi-expandable-wrapper {
  &.squared {
    .oi-expandable-wrapper-row {
      // Limiting the width so the container are always squared.
      // This restricts the user to just two elements per row,
      // but since it's not an issue in the original project
      // I'm accepting said compromise.
      margin: auto;
      @media (min-width: $oi-expandable-breakpoint-md) {
        width: calc(#{$oi-expandable-item-size-min} * 2);
      }
      @media (min-width: $oi-expandable-breakpoint-lg) {
        width: calc(#{$oi-expandable-item-size-mid} * 2);
      }
      @media (min-width: $oi-expandable-breakpoint-xl) {
        width: calc(#{$oi-expandable-item-size-max} * 2);
      }
    }
  }

  .oi-expandable-wrapper-row {
    // Each row is a flexbox, which is a column on responsive.
    display: flex;
    flex-direction: column;
    @media (min-width: $oi-expandable-breakpoint-md) {
      flex-direction: row;
    }

    .oi-expandable-item {
      // Take up all available width.
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: calc(100vw - 100px);
      height: $oi-expandable-item-size-min;
      padding: 52px 43px !important;
      transition: all $oi-expandable-transition-speed ease-in-out;
      margin-bottom: $oi-expandable-box-margin;

      // Prevents seeing the text wrap during animations.
      min-width: 0;

      @media (max-width: $oi-expandable-breakpoint-sm) {
        min-height: calc(100vw);
      }
      @media (min-width: $oi-expandable-breakpoint-md) {
        min-height: unset;
        &:nth-child(odd) {
          margin-right: $oi-expandable-box-margin / 2;
        }
        &:nth-child(even) {
          margin-left: $oi-expandable-box-margin / 2;
        }
        &:first-child:last-child {
          margin-right: 0;
          margin-left: 0;
        }
      }
      @media (min-width: $oi-expandable-breakpoint-lg) {
        height: $oi-expandable-item-size-mid - $oi-expandable-box-margin / 2;
        padding: 71px 54px !important;
      }
      @media (min-width: $oi-expandable-breakpoint-xl) {
        height: $oi-expandable-item-size-max - $oi-expandable-box-margin / 2;
        padding: 90px 54px !important;
      }



      .oi-expandable-icon {
        width: 90px;
        height: 90px;
        margin-bottom: 16px;
        opacity: 1;
        // This should only animate height and opacity, but for
        // some reason it doesn't work if I set it like that:
        transition: all $oi-expandable-transition-speed ease-in-out;
        z-index: 10;

        @media (min-width: $oi-expandable-breakpoint-lg) {
          width: 97px;
          height: 97px;
        }
        @media (min-width: $oi-expandable-breakpoint-xl) {
          width: 104px;
          height: 104px;
        }
      }

      .oi-expandable-title {
        position: relative;
        font-size: 2.5em;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.017em;
        transition: color $oi-expandable-transition-speed ease-in-out;
        z-index: 10;

        &.two-lines {
          font-size: 2em;
        }

        @media (min-width: $oi-expandable-breakpoint-lg) {
          font-size: 3em;
          &.two-lines {
            font-size: 2.5em;
          }
        }
        @media (min-width: $oi-expandable-breakpoint-xl) {
          font-size: 3.41em;
          &.two-lines {
            font-size: 3em;
          }
        }

      }

      .oi-expandable-content {
        // The content is initially hidden.
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: height, opacity $oi-expandable-transition-speed ease-in-out;
        transition-delay: $oi-expandable-transition-speed;
        position: relative;
        z-index: 10;
        margin-top: auto;
      }

      // Overlay, only visible when expanded.
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
        opacity: 0;
        transition: opacity $oi-expandable-transition-speed ease-in-out;
      }

      // Dark background, only visible when shrunk.
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background-image: linear-gradient(180deg, #002E5F 0%, #09132F 100%);
        opacity: 0;
        transition: opacity $oi-expandable-transition-speed ease-in-out;
      }

      // The expanded and shrunk styles have no effect on smaller screens.
      @media (min-width: $oi-expandable-breakpoint-md) {
        &.expanded {
          &:not(.icon-always-visible) {
            .oi-expandable-icon {
              opacity: 0;
              height: 0;
            }
          }

          .oi-expandable-title {
            color: $oi-expandable-color-expanded;
          }

          .oi-expandable-content {
            opacity: 1;
            height: auto;
          }

          &::after {
            opacity: 0.7;
          }

          h6 {
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.35;
            margin-bottom: 0;
            padding-top: 10px;
          }

          p {
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 0.005em;
            line-height: 1.25;
          }
        }

        &.shrunk {
          // Fixed at 99px wide.
          flex: 0 1 99px;

          .oi-expandable-icon {
            display: none;
          }

          .oi-expandable-title {
            color: $oi-expandable-color-expanded;
            transform: rotate(-90deg);
            position: absolute;
            top: calc(100% - 41px);
            left: 16px;
            transform-origin: top left;
            width: calc(#{$oi-expandable-item-size-min} - 60px);

            // Wrapping text when vertical.
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 1.9em;
            padding-top: 15px;

            &.two-lines {
              padding-top: 0;
              margin-left: -5px;
            }

            @media (min-width: $oi-expandable-breakpoint-lg) {
              width: calc(#{$oi-expandable-item-size-mid} - 60px);
              font-size: 2.7em;
              padding-top: 15px;
            }
            @media (min-width: $oi-expandable-breakpoint-xl) {
              width: calc(#{$oi-expandable-item-size-max} - 60px);
              font-size: 3em;
              padding-top: 0;
            }
          }

          .oi-expandable-content {
            display: none;
          }

          &::before {
            opacity: 1;
          }
        }
      }

      &.no-icons {
        min-height: calc((100vw - 100px) * 0.7);
        height: $oi-expandable-item-size-min * 0.7;

        @media (max-width: $oi-expandable-breakpoint-sm) {
          min-height: calc(70vw);
        }
        @media (min-width: $oi-expandable-breakpoint-md) {
          padding: 26px 40.5px !important;
          min-height: unset;
        }
        @media (min-width: $oi-expandable-breakpoint-lg) {
          height: $oi-expandable-item-size-mid * 0.7;
          padding: 53px 54px !important;
        }
        @media (min-width: $oi-expandable-breakpoint-xl) {
          height: $oi-expandable-item-size-max * 0.7;
          padding: 90px 54px !important;
        }

        &.expanded {
          h6 {
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.35;
            margin-bottom: 0;
            padding-top: 10px;
          }

          p {
            font-size: 1.405rem;
            font-weight: 300;
            letter-spacing: 0.005em;
            line-height: 1.25;
          }
        }

        &.shrunk {
          // Fixed at 66px wide.
          flex: 0 1 66px;
          padding: 32px 23px !important;

          @media (min-width: $oi-expandable-breakpoint-lg) {
            flex: 0 1 75px;
          }
          @media (min-width: $oi-expandable-breakpoint-xl) {
            flex: 0 1 99px;
          }

          .oi-expandable-title {
            width: calc(#{$oi-expandable-item-size-min} - 60px);

            font-size: 1.1em;
            padding-top: 5px;

            &.two-lines {
              padding-top: 0;
              margin-left: -3px;
              margin-top: 32px;
              font-size: 1.2em;
            }

            @media (min-width: $oi-expandable-breakpoint-lg) {
              width: calc(#{$oi-expandable-item-size-mid} - 60px);
              font-size: 1.65em;
              padding-top: 5px;

              &.two-lines {
                padding-top: 0;
                margin-left: -3px;
                margin-top: 15px;
                font-size: 1.5em;
              }
            }
            @media (min-width: $oi-expandable-breakpoint-xl) {
              width: calc(#{$oi-expandable-item-size-max} - 60px);
              font-size: 2.2em;
              padding-top: 15px;

              &.two-lines {
                padding-top: 0;
                margin-left: -5px;
                margin-top: 15px;
                font-size: 2em;
                .small {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
